@font-face {
  font-family: 'Heebo';
  src: local('Heebo'), url('./fonts/Heebo-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: "Heebo",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
