.buttonNewLaunchDiv{
    position: absolute;
    right: 15%;
    top: 90vh;
   
}

.buttonNewLaunch{
    /* background: linear-gradient(0deg, #138808 33%, #ffffff 33%, #ffffff 66%, #FF9933 66% ) !important; */
}

.buttonNewLaunch:hover{
    transform: scale(2);
}